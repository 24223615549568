import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit,Input,Inject, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5/dist/md5';
import { DatePipe } from '@angular/common';
import * as CryptoJS from 'crypto-js';
import * as jspdf from 'jspdf';
import domtoimage from 'dom-to-image';

// import jsPDF = require('jspdf') // // typescript without esModuleInterop flag
// import jsPDF from 'yworks-pdf' // using yworks fork
// import jsPDF from 'jspdf/dist/jspdf.node.debug' // for nodejs
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-printwaybill',
  templateUrl: './printwaybill.page.html',
  styleUrls: ['./printwaybill.page.scss'],
})
export class PrintwaybillPage implements OnInit {
  tdid;
  swhere;
  details = [];
  @ViewChild('myTemp') myTempRef: ElementRef;

  constructor(private auth:AuthService,private http:HttpClient,private route:ActivatedRoute) { 

    
  }

  async decypt():Promise<any>{
    return new Promise(async resolve =>{
      //this.swhere = this.swhere.toString().replace("%2F","/").replace("%3D","=");
      
      let ret =  await CryptoJS.AES.decrypt(this.swhere,"chupapimunyanyo").toString(CryptoJS.enc.Utf8);
     
      return resolve(ret);
    });
    
  }

  async get_info(){
    let frmdata = new FormData();
    let dt = "";
    this.tdid = this.tdid.split(',');
    this.tdid.forEach(element => {
      dt += (dt == "" ? "where tdid="+element : " or tdid="+element);
    });


    frmdata.append('swhere',dt);
    const httpoptions = {
      headers:new HttpHeaders()
    }
    this.http.post(environment.api_url + `Itcwaybill/get_waybill_info_multiple`,frmdata,httpoptions).subscribe((res:any) => {
      this.details = res;
      setTimeout(async () => {
       window.print();
            
      }, (800));
    }, (err) => {
      alert(JSON.stringify(err));
    })
  }




  async ngOnInit() {

    this.route.queryParams.subscribe(async params => {

      if (params && params.tdid) {
        this.tdid = params.tdid;
       
        this.auth.loadToken().then(() => {
          this.get_info();
        })
        
      } 
    });

  }

}
