import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-printsummary',
  templateUrl: './printsummary.page.html',
  styleUrls: ['./printsummary.page.scss'],
})
export class PrintsummaryPage implements OnInit {
  tdid;
  details;
  constructor(private http:HttpClient,private route:ActivatedRoute,private auth:AuthService) { }

  ngOnInit() {

    this.route.queryParams.subscribe(async params => {

      if (params && params.tdid) {
        this.tdid = params.tdid;
       
        this.auth.loadToken().then(() => {
          this.get_info();
        })
        
      } 
    });

  }

  async get_info(){
    let frmdata = new FormData();
    let dt = "";
    this.tdid = this.tdid.split(',');
    this.tdid.forEach(element => {
      dt += (dt == "" ? "where tdid="+element : " or tdid="+element);
    });


    frmdata.append('swhere',dt);
    const httpoptions = {
      headers:new HttpHeaders()
    }
    this.http.post(environment.api_url + `Itcwaybill/get_waybill_info_multiple`,frmdata,httpoptions).subscribe((res:any) => {
      this.details = res;
      setTimeout(async () => {
       window.print();
            
      }, (800));
    }, (err) => {
      alert(JSON.stringify(err));
    })
  }


}
