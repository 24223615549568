import { formatDate } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { environment } from 'src/environments/environment';


const httpOptions = { headers: new HttpHeaders()}
@Injectable({
  providedIn: 'root'
})
export class DataService {
  sender_list = [];
  waybill_list = [];
  default_info;
  date_today = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  loading_waybill_list = false;


  constructor(private http:HttpClient, private alertController:AlertController) {
    
  }

   load_data(clbid){
    //sender
    this.http.get(environment.api_url + `Itcwaybill/get_sender_list/${clbid}/${this.date_today}/${this.date_today}`).subscribe((res:any)=>{
      this.sender_list = res;
    },(err)=>{
      this.sender_list = [];
    });

    //waybill list
    this.loading_waybill_list = true;
    this.http.get(environment.api_url + `Itcwaybill/get_waybill_list/${clbid}/${this.date_today}/${this.date_today}`).subscribe((res:any)=>{
      this.loading_waybill_list = false;  
      this.waybill_list = res;
    },(err)=>{
      this.loading_waybill_list = false;
    });
   }

   load_default_info(clbid){
     this.http.get(environment.api_url + `Itcwaybill/get_default_info/${clbid}`).subscribe((res:any) =>{
      this.default_info = res;
     },(err)=>{
       this.default_info = [];
     });
   }
}
