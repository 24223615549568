import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PrintsummaryPage } from './pages/printsummary/printsummary.page';
import { PrintwaybillPage } from './pages/printwaybill/printwaybill.page';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canLoad:[AuthGuard]
  },
  {
    path: 'newwaybill',
    loadChildren: () => import('./pages/newwaybill/newwaybill.module').then( m => m.NewwaybillPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'selectsender',
    loadChildren: () => import('./pages/selectsender/selectsender.module').then( m => m.SelectsenderPageModule)
    ,canLoad:[AuthGuard]
  },
  {
    path: 'viewdetails',
    loadChildren: () => import('./pages/viewdetails/viewdetails.module').then( m => m.ViewdetailsPageModule)
  },
  {
    path: 'viewstatushistory',
    loadChildren: () => import('./pages/viewstatushistory/viewstatushistory.module').then( m => m.ViewstatushistoryPageModule)
  },
  { path: 'printwaybill/:tdid',
    outlet: 'print',
    component: PrintwaybillPage
  },
  { path: 'printsummary/:tdid',
    outlet: 'print',
    component: PrintsummaryPage
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'printsummary',
    loadChildren: () => import('./pages/printsummary/printsummary.module').then( m => m.PrintsummaryPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
