import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
// Import
import { HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe } from '@angular/common';
import {LocationStrategy, HashLocationStrategy} from '@angular/common';
import { PrintwaybillPage } from './pages/printwaybill/printwaybill.page';
import { PrintsummaryPage } from './pages/printsummary/printsummary.page';
@NgModule({
  declarations: [AppComponent,PrintwaybillPage,PrintsummaryPage],
  entryComponents: [],
  imports: [BrowserModule,RouterModule,HttpClientModule,ReactiveFormsModule,BrowserModule, IonicModule.forRoot(), AppRoutingModule],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},DatePipe,{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
