import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import jwt_decode from "jwt-decode";
import { DataService } from './data.service';
const token = "eyJ0eXAiO.../// jwt token";

const TOKEN_KEY = "GlMwAsHeRe!@#$%^&*()";
const store = new Storage();


const httpOptions = {
  headers:new HttpHeaders()
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  lasterror = '';
  userData;


  constructor(private data:DataService,private http:HttpClient) { 

    
  }

  async ngOnInit(){
    await store.create();
  }

  async loadToken():Promise<boolean> {
    await store.create();
    return new Promise(async (resolve,reject)=>{
      store.get(TOKEN_KEY).then(async token =>{

        if (token) {
          let decoded = await jwt_decode(token);
          this.userData = decoded;
          //LOAD DATA
          this.data.load_data(this.userData.clbid);
          return resolve(true);
        } else {
          
          return resolve(false);
        }

      });
      
    });
    
  }
 
  async login(credentials: {username, password}): Promise<boolean> {
    await store.create();
    return new Promise((resolve,reject)=>{

      this.http.post(environment.api_url + `itcwaybill/login`, credentials,httpOptions).subscribe(async (res)=>{
    
        if(res['s'] == 1){
          await store.set(TOKEN_KEY,res['token']).then(()=>{
            this.loadToken().then(()=>{
              resolve(true);
            });
            
          });
        }else{
          this.lasterror = res['errmsg'];
          resolve(false);
        }
      },(err)=>{

        this.lasterror = JSON.stringify(err);
        resolve(false);
      });

    });
    
  }
 
  logout(): Promise<void> {
    return store.remove(TOKEN_KEY);
  }

}
