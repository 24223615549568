
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {


  constructor(private router:Router,private auth:AuthService){

  }
  
  async canLoad():Promise<boolean>{
    let res = await this.auth.loadToken();
    
    if (res) {
      return true;
    } else {
      this.router.navigateByUrl('/login', { replaceUrl:true });
      return false;
    }

  }


  
}
